import { A11y, Autoplay, Keyboard, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import { SwiperOptions } from 'swiper/types';
import Swiper from 'swiper';

const defaultOptions: SwiperOptions = {
  modules: [A11y, Pagination, Navigation, Keyboard],
  keyboard: true,
  a11y: {
    enabled: true,
    containerRole: 'listbox',
    containerRoleDescriptionMessage: 'carousel',
    slideRole: 'option',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    enabled: false,
    nextEl: '.js-slider-next',
    prevEl: '.js-slider-prev',
  },
  breakpoints: {
    640: {
      navigation: {
        enabled: true,
      },
    },
  },
};

const heroOptions: SwiperOptions = {
  modules: [A11y, Pagination, Autoplay, Keyboard],
  keyboard: true,
  a11y: {
    enabled: true,
    containerRole: 'listbox',
    containerRoleDescriptionMessage: 'carousel',
    slideRole: 'option',
  },
  loop: true,
  slidesPerView: 1,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  autoplay: {
    delay: 5000,
    pauseOnMouseEnter: true,
  },
};

const videoOptions: SwiperOptions = {
  modules: [A11y, Navigation, Keyboard],
  keyboard: true,
  a11y: {
    enabled: true,
    containerRole: 'listbox',
    containerRoleDescriptionMessage: 'carousel',
    slideRole: 'option',
  },
  slidesPerView: 'auto',
  spaceBetween: 24,
  navigation: {
    enabled: false,
    nextEl: '.js-slider-next',
    prevEl: '.js-slider-prev',
  },
  breakpoints: {
    640: {
      navigation: {
        enabled: true,
      },
    },
    768: {
      navigation: {
        enabled: true,
      },
    },
    1024: {
      navigation: {
        enabled: true,
      },
    },
    1728: {
      navigation: {
        enabled: true,
      },
    },
  },
};

const matchesOptions: SwiperOptions = {
  modules: [A11y, Navigation, Keyboard],
  keyboard: true,
  a11y: {
    enabled: true,
    containerRole: 'listbox',
    containerRoleDescriptionMessage: 'carousel',
    slideRole: 'option',
  },
  slidesPerView: 'auto',
  spaceBetween: 24,
  navigation: {
    enabled: false,
    nextEl: '.js-slider-next',
    prevEl: '.js-slider-prev',
  },
  breakpoints: {
    640: {
      navigation: {
        enabled: true,
      },
    },
    768: {
      spaceBetween: 40,
      navigation: {
        enabled: true,
      },
    },
    1024: {
      spaceBetween: 40,
      navigation: {
        enabled: true,
      },
    },
    1728: {
      spaceBetween: 40,
      navigation: {
        enabled: true,
      },
    },
  },
};

const scheduleOptions: SwiperOptions = {
  modules: [A11y, Navigation, Keyboard],
  keyboard: true,
  a11y: {
    enabled: true,
    containerRole: 'listbox',
    containerRoleDescriptionMessage: 'carousel',
    slideRole: 'option',
  },
  slidesPerView: 1,
  autoHeight: true,
  navigation: {
    enabled: true,
    nextEl: '.js-slider-next',
    prevEl: '.js-slider-prev',
  },
  spaceBetween: 48,
};

export const setupSlider = (): void => {
  const sliders: NodeListOf<HTMLElement> = document.querySelectorAll('.js-slider');

  if (sliders.length <= 0) {
    return;
  }

  sliders.forEach((element: HTMLElement): void => {
    const sliderType: string | undefined = element.dataset.type;

    let options: SwiperOptions = defaultOptions;

    if (typeof sliderType === 'undefined') {
      new Swiper(element, options);
      return;
    }

    if (sliderType === 'video') {
      options = videoOptions;
    }
    if (sliderType === 'matches') {
      options = matchesOptions;
    }
    if (sliderType === 'hero') {
      options = heroOptions;
    }
    if (sliderType === 'schedule') {
      options = scheduleOptions;
      options.initialSlide = parseInt(element.dataset.initialSlide ?? '0');
    }

    const identifier: string | undefined = element.dataset.identifier;
    if (typeof identifier !== 'undefined') {
      options = Object.assign(options, {
        navigation: {
          enabled: false,
          nextEl: `.js-slider-next[data-identifier="${identifier}"]`,
          prevEl: `.js-slider-prev[data-identifier="${identifier}"]`,
        },
      });
    }

    new Swiper(element, options);
  });
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
document.addEventListener('datastar-sse', (e: Event & { detail: { type: string } }) => {
  if (e.detail.type === 'finished') {
    window.scrollTo({ top: 0 });
    setupSlider();
  }

  if (e.detail.type === 'started') {
    const activeSliders: NodeListOf<HTMLElement & { swiper: Swiper }> =
      document.querySelectorAll('#result .js-slider');
    if (activeSliders.length > 0) {
      activeSliders.forEach((element: HTMLElement & { swiper: Swiper }) => {
        try {
          element.swiper?.destroy();
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (e) {
          // no-op
        }
      });
    }
  }
});
